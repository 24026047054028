<template>
    <!-- Confirm Action Modal -->
    <component
        class="text-left"
        v-if="showActionModal"
        :show="showActionModal"
        :is="actionModalIs"
        :working="working"
        :selected-resources="selectedResources"
        :resource-name="resourceName"
        :action="action"
        :errors="errors"
        @confirm="$emit('action-confirmed', $event)"
        @close="$emit('action-closed', $event)"
    />

    <!-- Response Action Modal -->
    <component
        v-if="showResponseModal"
        :show="showResponseModal"
        :is="responseModalIs"
        @confirm="$emit('response-modal-closed', $event)"
        @close="$emit('response-modal-closed', $event)"
        :data="responseModalData"
    />
</template>

<script>
    import ConfirmActionModal from "@nova/components/Modals/ConfirmActionModal.vue";
    import Modal from "@nova/components/Modals/Modal.vue";

    export default {
        components: {
            ConfirmActionModal,
            Modal,
        },

        emits: ["action-confirmed", "action-closed", "response-modal-closed"],

        props: [
            "showActionModal",
            "actionModalIs",
            "working",
            "selectedResources",
            "resourceName",
            "action",
            "errors",
            "showResponseModal",
            "responseModalIs",
            "responseModalData",
        ],
    };
</script>
