<template>
    <card
        class="flex flex-col justify-center items-center gap-2 p-6 text-gray-300 dark:text-gray-500"
        :dusk="`${resourceName}-empty-dialog`"
    >
        <icon
            name="mdi-table-plus"
            class="w-20 h-20"
        />

        <h3 class="text-base font-normal italic m-0 p-0 text-gray-500">
            {{
                __("No :resource could be found.", {
                    resource: resourceLabel,
                })
            }}
        </h3>

        <!-- Create / Attach Button -->
        <create-resource-button
            type="outline"
            :authorized-to-create="authorizedToCreate"
            :authorized-to-relate="authorizedToRelate"
            :create-button-label="createButtonLabel"
            :resource-information="resourceInformation"
            :resource-name="resourceName"
            :resource-label="resourceLabel"
            :via-resource="viaResource"
            :via-resource-id="viaResourceId"
            :via-relationship="viaRelationship"
            :relationship-type="relationshipType"
        />
    </card>
</template>

<script>
    import Card from "@nova/components/Card.vue";
    import Icon from "@nova/components/Icon.vue";
    import CreateResourceButton from "@nova/components/Buttons/CreateResourceButton.vue";

    export default {
        components: {
            Card,
            Icon,
            CreateResourceButton,
        },

        props: [
            "create-button-label",
            "resourceLabel",
            "resourceInformation",
            "resourceName",
            "viaResource",
            "viaResourceId",
            "viaRelationship",
            "relationshipType",
            "authorizedToCreate",
            "authorizedToRelate",
        ],
    };
</script>
