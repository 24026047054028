<template>
    <div
        v-if="actionsForSelect.length > 0"
        class="flex"
    >
        <v-button
            :icon="actionIcon"
            size="md"
            padding="tight"
            :severity="actionSeverity"
            class="rounded-r-none"
            @click="handleRunSelection"
        />
        <select-control
            v-bind="$attrs"
            ref="actionSelectControl"
            @change="handleSelectionChange"
            :selected="selectedActionKey"
            :options="actionsForSelect"
            dusk="action-select"
            class="flex-1 text-black"
            control-class="rounded-l-none"
            :aria-label="__('Select Action')"
        >
            <option
                value=""
                disabled
                selected
            >
                {{ __("Select Action") }}
            </option>
        </select-control>

        <action-modals
            :show-action-modal="actionModalVisible"
            :action-modal-is="selectedAction?.component"
            :working="working"
            :selected-resources="selectedResources"
            :resource-name="resourceName"
            :action="selectedAction"
            :errors="errors"
            @action-confirmed="executeAction"
            @action-closed="closeConfirmationModal"
            :show-response-modal="responseModalVisible"
            :response-modal-is="actionResponseData?.modal"
            :response-modal-data="actionResponseData"
            @response-modal-closed="closeResponseModal"
        />
    </div>
</template>

<script>
    import ActionHandler from "@nova/extendables/ActionHandler.vue";
    import VButton from "@nova/components/Buttons/VButton.vue";
    import SelectControl from "@nova/components/Inputs/SelectControl.vue";
    import ActionModals from "@nova/components/Modals/ActionModals.vue";

    export default {
        extends: ActionHandler,

        components: {
            VButton,
            SelectControl,
            ActionModals,
        },

        props: {
            pivotName: { type: String, default: null },
        },

        methods: {
            handleRunSelection() {
                this.determineActionStrategy();

                this.$refs.actionSelectControl.resetSelection();
            },

            handleSelectionChange(event) {
                this.setSelectedActionKey(event);
            },
        },

        computed: {
            actionIcon() {
                if (!this.selectedAction) {
                    return "mdi-play";
                }

                if (this.selectedAction.withoutConfirmation) {
                    return "mdi-fast-forward";
                }

                return "mdi-play";
            },

            actionSeverity() {
                return this.selectedAction?.destructive ? "alert" : "info";
            },

            actionsForSelect() {
                return [
                    ...this.availableActions.map((a) => ({
                        value: a.uriKey,
                        label: a.name,
                        disabled: a.authorizedToRun === false,
                    })),
                    ...this.availablePivotActions.map((a) => ({
                        group: this.pivotName,
                        value: a.uriKey,
                        label: a.name,
                        disabled: a.authorizedToRun === false,
                    })),
                ];
            },
        },
    };
</script>
