<template>
    <div>
        <action-modals
            :show-action-modal="actionModalVisible"
            :action-modal-is="selectedAction?.component"
            :working="working"
            :selected-resources="selectedResources"
            :resource-name="resourceName"
            :action="selectedAction"
            :errors="errors"
            @action-confirmed="executeAction"
            @action-closed="closeConfirmationModal"
            :show-response-modal="responseModalVisible"
            :response-modal-is="actionResponseData?.modal"
            :response-modal-data="actionResponseData"
            @response-modal-closed="closeResponseModal"
        />
        <popper
            placement="bottom-end"
            trigger="click"
        >
            <template #trigger>
                <slot name="trigger">
                    <v-button
                        variant="ghost"
                        icon="mdi-dots-horizontal"
                        v-tooltip="__('Actions')"
                    />
                </slot>
            </template>

            <template #content>
                <dropdown-menu width="auto">
                    <dropdown-menu-item
                        v-for="action in actions"
                        :key="action.uriKey"
                        :data-action-id="action.uriKey"
                        as="button"
                        class="border-none"
                        @click="() => handleClick(action)"
                        :title="action.name"
                        :disabled="action.authorizedToRun === false"
                    >
                        {{ action.name }}
                    </dropdown-menu-item>

                    <slot name="menu" />
                </dropdown-menu>
            </template>
        </popper>
    </div>
</template>

<script>
    import ActionHandler from "@nova/extendables/ActionHandler.vue";
    import Popper from "@nova/components/Popper.vue";
    import ActionModals from "@nova/components/Modals/ActionModals.vue";

    export default {
        extends: ActionHandler,

        components: {
            ActionModals,
            Popper,
        },

        emits: ["actionExecuted"],

        methods: {
            handleClick(action) {
                if (action.authorizedToRun !== false) {
                    this.handleActionClick(action.uriKey);
                }
            },
        },
    };
</script>
