<template>
    <v-button
        v-if="shouldShowButtons"
        :as="buttonType"
        :variant="variant"
        :severity="severity"
        :icon="icon"
        :padding="padding"
        size="sm"
        :href="buttonURL"
        :label="createOrAttachButtonLabel"
        dusk="create-button"
        tabindex="1"
        @click.stop
    />
</template>

<script>
    import VButton from "@nova/components/Buttons/VButton.vue";
    import { mapProps } from "@nova/utils";

    export default {
        components: {
            VButton,
        },
        props: {
            ...mapProps([
                "resourceName",
                "viaResource",
                "viaResourceId",
                "viaRelationship",
                "relationshipType",
            ]),
            createButtonLabel: {
                type: String,
                required: false,
            },
            resourceLabel: {
                type: String,
            },
            resourceInformation: {
                type: Object,
                required: false,
            },
            type: {
                type: String,
                default: "button",
                validator: (v) => ["button", "outline"].includes(v),
            },
            authorizedToCreate: {
                type: Boolean,
                default: false,
            },
            authorizedToRelate: {
                type: Boolean,
                default: false,
            },
            alreadyFilled: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            variant() {
                return this.type === "button" ? "solid" : "outline";
            },

            severity() {
                return this.type === "button" ? "muted" : "primary";
            },

            icon() {
                return this.type === "button" ? "mdi-plus" : null;
            },

            padding() {
                return this.type === "button" ? "tight" : "default";
            },

            shouldShowAttachButton() {
                return (
                    (this.relationshipType === "belongsToMany" ||
                        this.relationshipType === "morphToMany") &&
                    this.authorizedToRelate
                );
            },

            shouldShowCreateButton() {
                return (
                    this.authorizedToCreate &&
                    this.authorizedToRelate &&
                    !this.alreadyFilled
                );
            },

            shouldShowButtons() {
                return (
                    this.shouldShowAttachButton || this.shouldShowCreateButton
                );
            },

            createOrAttachButtonLabel() {
                return this.shouldShowAttachButton
                    ? this.__("Attach :resource", {
                          resource: this.resourceLabel,
                      })
                    : this.createButtonLabel ||
                          this.__("Create :resource", {
                              resource: this.resourceLabel,
                          });
            },

            buttonType() {
                if (this.shouldShowAttachButton) {
                    return "link";
                }

                return this.resourceInformation?.routeToCreate ? "a" : "link";
            },

            buttonURL() {
                if (this.shouldShowAttachButton) {
                    return this.$url(
                        `/resources/${this.viaResource}/${this.viaResourceId}/attach/${this.resourceName}`,
                        {
                            viaRelationship: this.viaRelationship,
                            polymorphic:
                                this.relationshipType === "morphToMany"
                                    ? "1"
                                    : "0",
                        },
                    );
                }

                return (
                    this.resourceInformation?.routeToCreate ||
                    this.$url(`/resources/${this.resourceName}/new`, {
                        viaResource: this.viaResource,
                        viaResourceId: this.viaResourceId,
                        viaRelationship: this.viaRelationship,
                        relationshipType: this.relationshipType,
                    })
                );
            },
        },
    };
</script>
