<template>
    <loading-view :loading="initialLoading">
        <template v-if="shouldOverrideMeta && resourceInformation">
            <app-head
                :title="headingTitle"
                :section="resourceInformation.group"
            />

            <teleport to="#page-title">
                {{ headingTitle }}
            </teleport>
        </template>
        <div
            v-else
            class="flex items-center justify-between mb-2"
        >
            <heading
                :level="2"
                dusk="index-heading"
                class="flex items-center"
            >
                {{ headingTitle }}
            </heading>

            <div :id="`${resourceName}-${resourceId}-panel-actions`" />
        </div>

        <teleport
            :to="
                shouldOverrideMeta
                    ? '#page-actions'
                    : `#${resourceName}-${resourceId}-panel-actions`
            "
        >
            <detail-action-buttons
                :resource="resource"
                :resource-id="resourceId"
                :actions="actions"
                :via-resource="viaResource"
                :via-resource-id="viaResourceId"
                :via-relationship="viaRelationship"
                :resource-name="resourceName"
                @actionExecuted="actionExecuted"
                @resource-deleted="getResource"
                @resource-restored="getResource"
                v-if="actions.length > 0 || canModifyResource"
            />
        </teleport>

        <!-- @todo: Cards-->

        <!-- Resource Detail -->
        <panel-grid
            :dusk="`${resourceName}-detail-component`"
            :class="{
                'mx-4': !viaRelationship,
            }"
        >
            <component
                v-for="panel in panels"
                :key="panel.id"
                :is="resolveComponentName(panel)"
                :panel="panel"
                :resource="resource"
                :resource-id="resourceId"
                :resource-name="resourceName"
                :via-relationship="viaRelationship"
            />
        </panel-grid>
    </loading-view>
</template>

<script>
    import ResourcePage from "@nova/extendables/ResourcePage.vue";
    import isNil from "lodash/isNil";
    import { Errors } from "form-backend-validation";
    import { computed } from "vue";
    import { mapGetters, mapActions } from "vuex";
    import { mapProps } from "@nova/utils";

    export default {
        extends: ResourcePage,

        props: {
            ...mapProps(["resourceId"]),
            resourceLabel: {
                type: String,
                default: null,
            },
        },

        provide() {
            return {
                pageTitle: computed(() => this.headingTitle),
            };
        },

        data: () => ({
            initialLoading: true,
            loading: true,

            title: null,
            resource: null,
            panels: [],
            actions: [],
            actionValidationErrors: new Errors(),
        }),

        created() {
            if (Nova.missingResource(this.resourceName)) {
                return Nova.visit("/404");
            }

            if (this.shouldEnableShortcut === true) {
                Nova.addShortcut("e", this.handleKeydown);
            }
        },

        beforeUnmount() {
            if (this.shouldEnableShortcut === true) {
                Nova.disableShortcut("e");
            }
        },

        mounted() {
            this.initializeComponent();
        },

        methods: {
            ...mapActions(["startImpersonating"]),

            handleResourceLoaded() {
                this.loading = false;

                Nova.$emit("resource-loaded", {
                    resourceName: this.resourceName,
                    resourceId: this.resourceId.toString(),
                    mode: "detail",
                });
            },

            handleKeydown(e) {
                if (
                    this.resource.authorizedToUpdate &&
                    e.target.tagName != "INPUT" &&
                    e.target.tagName != "TEXTAREA" &&
                    e.target.contentEditable != "true"
                ) {
                    Nova.visit(
                        this.resource.routeToUpdate
                            ? {
                                  path: this.resource.routeToUpdate,
                                  remote: true,
                              }
                            : `/resources/${this.resourceName}/${this.resourceId}/edit`,
                    );
                }
            },

            async initializeComponent() {
                await Promise.all([this.getResource(), this.getActions()]);

                this.initialLoading = false;
            },

            getResource() {
                this.loading = true;
                this.panels = null;
                this.resource = null;

                return this.$http
                    .get(`/nova-api/${this.resourceName}/${this.resourceId}`, {
                        params: {
                            viaResource: this.viaResource,
                            viaResourceId: this.viaResourceId,
                            viaRelationship: this.viaRelationship,
                            relationshipType: this.relationshipType,
                        },
                    })
                    .then(({ data: { title, panels, resource } }) => {
                        this.title = title;
                        this.panels = this.autoStackPanels(panels);
                        this.resource = resource;

                        this.handleResourceLoaded();
                    })
                    .catch((error) => {
                        if (!error.response) {
                            Nova.$emit("error", error.message);
                            console.error(error);
                            return;
                        }

                        if (error.response.status >= 500) {
                            Nova.$emit("error", error.response.data.message);
                            return;
                        }

                        if (
                            error.response.status === 404 &&
                            this.initialLoading
                        ) {
                            Nova.visit("/404");
                            return;
                        }

                        if (error.response.status === 403) {
                            Nova.visit("/403");
                            return;
                        }

                        if (error.response.status === 401)
                            return Nova.redirectToLogin();

                        Nova.error(this.__("This resource no longer exists"));

                        Nova.visit(`/resources/${this.resourceName}`);
                    });
            },

            async getActions() {
                this.actions = [];

                try {
                    const response = await this.$http.get(
                        `/nova-api/${this.resourceName}/actions`,
                        {
                            params: {
                                resourceId: this.resourceId,
                                editing: true,
                                editMode: "create",
                                display: "detail",
                            },
                        },
                    );

                    this.actions = response.data?.actions;
                } catch (error) {
                    console.error(error);
                    Nova.error(
                        this.__("Unable to load actions for this resource"),
                    );
                }
            },

            async actionExecuted() {
                await Promise.all([this.getResource(), this.getActions()]);
            },

            resolveComponentName(panel) {
                return isNil(panel.prefixComponent) || panel.prefixComponent
                    ? "detail-" + panel.component
                    : panel.component;
            },
        },

        computed: {
            ...mapGetters(["currentUser"]),

            headingTitle() {
                return this.__(":resource Details:title:softDeleted", {
                    resource:
                        this.resourceLabel ||
                        this.resourceInformation?.singularLabel,
                    title: this.title ? `: ${this.title}` : "",
                    softDeleted: this.resource?.softDeleted
                        ? " (" + this.__("Soft Deleted") + ")"
                        : "",
                });
            },

            canBeImpersonated() {
                return (
                    this.currentUser.canImpersonate &&
                    this.resource.authorizedToImpersonate
                );
            },

            shouldShowActionDropdown() {
                return (
                    this.resource &&
                    (this.actions.length > 0 || this.canModifyResource)
                );
            },

            canModifyResource() {
                return (
                    this.resource.authorizedToReplicate ||
                    this.canBeImpersonated ||
                    (this.resource.authorizedToDelete &&
                        !this.resource.softDeleted) ||
                    (this.resource.authorizedToRestore &&
                        this.resource.softDeleted) ||
                    this.resource.authorizedToForceDelete
                );
            },

            isActionDetail() {
                return this.resourceName === "action-events";
            },

            cardsEndpoint() {
                return `/nova-api/${this.resourceName}/cards`;
            },

            extraCardParams() {
                return {
                    resourceId: this.resourceId,
                };
            },
        },
    };
</script>
